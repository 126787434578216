<template>
	<div class="row">
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body">
               <div class="row">
                  <div class="col">
                     <h4 class="limitador font-22 mb-3">{{ aula.tituloAula }}</h4>
                  </div>
                  <div class="w-max-content">
                     <i class="far fa-undo-alt font-20 cursor-pointer float-end" @click="fecharAula"></i>
                  </div>
               </div>

               <div class="row">
                  <div class="col-xl-6 col-xxl-8 mb-4 mb-xxl-0" id="video">
                     <div class="ratio ratio-16x9" v-if="String(aula.link).startsWith('https://www.youtube.com/embed/') || String(aula.link).startsWith('https://iframe.mediadelivery.net/embed/')">
                        <iframe class="rounded" :src="aula.link" title="Video player" ref="myIframe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;" allowfullscreen></iframe>
                     </div>
                     <p class="mb-3" v-else><i class="far fa-info-circle font-12 color-theme me-1"></i> {{ aula.descricao }}</p>
                  </div>
                  <div class="col-xl-6 col-xxl-4" id="aulas">
                     <h4 class="limitador font-20 mb-2">{{ bloco.titulo }}</h4>
                     <div class="card mb-1 cursor-pointer" :class="index % 2 == 0 ? 'invert' : ''" v-for="(aula, index) in bloco.aulas" :key="index">
                        <div class="card-body">
                           <div class="row align-items-center">
                              <div class="col" @click="abrirAula(aula)">
                                 <h6 class="limitador-2 font-16 weight-400 mb-0" :class="aula.idAula == aulaAtual ? 'color-theme' : ''">{{ aula.tituloAula }}</h6>
                              </div>
                              <div class="w-max-content">
                                 <i class="far font-18 cursor-pointer" :class="aula.visualizado == true ? 'fa-check-circle text-success' : 'fa-times-circle'" @click="saveAulaMarcada(aula)"></i>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="String(aula.link).startsWith('https://www.youtube.com/embed/') || String(aula.link).startsWith('https://iframe.mediadelivery.net/embed/')">
         <div class="card mb-2">
            <div class="card-body">
               <h4 class="limitador font-22 mb-3">Descrição</h4>
               <p class="mb-0">{{ aula.descricao }}</p>
            </div>
         </div>
      </div>



      <!-- <div class="col-11">
         <h3 class="ml-2 limitador" @click="searchAllFromBloco">{{aula.tituloAula}}</h3>
      </div>
      <div class="col-1 align-right" @click="fecharAula">
         <i class="fa fa-chevron-circle-left font-20 cursor-pointer"></i>
      </div>
      <div class="col-lg-9 col-12">
         <div class="card spaceVideo">
            <iframe class="rounded iframeMobile" width="100%"
               :src="aula.link" 
               title="Video player" ref="myIframe" frameborder="0" 
               allow="accelerometer; autoplay; clipboard-write; encrypted-media;" 
               allowfullscreen v-if="String(aula.link).startsWith('https://www.youtube.com/embed/') || String(aula.link).startsWith('https://iframe.mediadelivery.net/embed/')">
            </iframe>
            <div class="col-12 my-5 text-center align-self-center" v-else>Sem vídeo aula</div>
         </div>
      </div>
      <div class="col-12 mb-2" v-if="screenWidth < 600">
         <span>{{aula.descricao}}</span>
      </div>
      <div class="col-12 col-lg-3">
         <div class="card spaceAulas scrolavel">
            <div class="mt-2 mb-2">
               <h4 class="ml-2 limitador">{{bloco.titulo}}</h4>
            </div>
            <div v-for="(aula, index) in bloco.aulas" :key="index">
               <div class="d-flex m-2 p-2 rounded cursor-pointer" :class="aula.idAula == aulaAtual ? 'cardSelecionado' : 'cardAulas'">
                  <div class="col-11" @click="abrirAula(aula)">
                     <span class="limitador">
                        <i class="fa fa-circle font-7 cursor-pointer mr-1 align-self-center" :class="aula.idAula == aulaAtual ? 'color-theme' : ''"></i> 
                        {{aula.tituloAula}}
                     </span>
                  </div>
                  <div class="col-1">
                     <div>
                        <i class="fa font-18 cursor-pointer" :class="aula.visualizado == true ? 'fa-check-circle colorCheck' : 'fa-times-circle'" @click="saveAulaMarcada(aula)"></i>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12" v-if="screenWidth > 600">
         <span>{{aula.descricao}}</span>
      </div> -->
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'Aula',
	data: function() {
		return {
         pesquisa: {'resultado': []},
         bloco: [],
         aula: {},
         moduloAtual: null,
         blocoAtual: null,
         aulaAtual: null,
         ligado: false,
         screenWidth: window.innerWidth,
		}
	},
   created() {
      this.moduloAtual = this.$route.params.idModulo;
      this.blocoAtual = this.$route.params.idBloco;
      this.aulaAtual = this.$route.params.idAula;
   },
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
   methods: {
      searchAllFromBloco : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchAllFromBloco',
            params: {
               id: this.moduloAtual,
               idUsuario: this.dadosUsuario.id
            }
         }).then(response => {
				ref.pesquisa.resultado = response.data;

            this.loadBloco()

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      loadBloco : function() {
         this.bloco = this.pesquisa.resultado.find(bloco => bloco.id == this.blocoAtual);
         this.loadAula()
      },
      loadAula : function() {
         if (this.bloco && this.bloco.aulas) {
            this.aula = this.bloco.aulas.find(aula => aula.idAula == this.aulaAtual);
         }
      },
      abrirAula: function(aula) {
         if (this.aulaAtual != aula.idAula) {
            this.$store.dispatch('alternarTelaCarregamento', true)
            this.aulaAtual = aula.idAula
            this.loadAula()
            this.$router.push('/admAula/' + this.moduloAtual + '/' + this.blocoAtual + '/' + aula.idAula);
            this.$store.dispatch('alternarTelaCarregamento', false)
         }
      },
      fecharAula : function() {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$router.push('/admClassroom/' + this.moduloAtual);
         this.$store.dispatch('alternarTelaCarregamento', false)
      },
      saveAulaMarcada : function(aula) {
         let ref = this;

         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/saveAulaMarcada',
            params: {
               idAula: aula.idAula,
               idUsuario: this.dadosUsuario.id
            }

         }).then(function () {
            ref.setAulaMarcada(aula)
            ref.searchAllFromBloco()
            setTimeout(() => {
               ref.loadBloco()
               ref.loadAula()
            }, 100);

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Data inválida!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalEditarPacote').modal('hide')
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      setAulaMarcada : function(aula) {
         let ref = this;

         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/setAulaMarcada',
            params: {
               idAula: aula.idAula,
               idUsuario: this.dadosUsuario.id,
               idModulo: this.moduloAtual
            }

         }).then(function () {

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Data inválida!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalEditarPacote').modal('hide')
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
   },
   mounted() {
      this.searchAllFromBloco();
      // setTimeout(() => {
      //    this.loadBloco()
      // }, 100);

      setTimeout(() => {
         $("#aulas").height($("#video").height() +'px')
      }, 500);

      $(window).resize(() => {
         $("#aulas").height($("#video").height() +'px')
      });
   },
}

</script>

<style scoped>

#aulas {
   min-height: 500px;
   overflow-y: auto;
}

#video {
   height: max-content;
}

</style>